import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "../../services/firebase";
import { doc, onSnapshot, setDoc } from "firebase/firestore";
import "./tablePage.css";

function TablePage() {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [consultations, setConsultations] = useState([]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        subscribeToConsultations();
      } else {
        navigate("/SignIn");
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const subscribeToConsultations = () => {
    const docRef = doc(db, "consultations", "allConsultations");
    const unsubscribe = onSnapshot(
      docRef,
      (docSnap) => {
        if (docSnap.exists()) {
          const data = docSnap.data();
          const consultationsArray = Object.entries(data).map(
            ([id, consultation]) => ({
              id,
              ...consultation,
            })
          );
          // Sort consultations by date, from most recent to oldest
          const sortedConsultations = consultationsArray.sort(
            (a, b) => b.createdAt.toDate() - a.createdAt.toDate()
          );
          setConsultations(sortedConsultations);
        }
      },
      (error) => {
        console.error("Error fetching consultations:", error);
      }
    );

    return unsubscribe;
  };
  async function checked(e, id) {
    console.log("Checkbox value:", e.target.checked);
    console.log("Row ID:", id);
    await setDoc(
      doc(db, "consultations", "allConsultations"),
      {
        [id]: { completed: e.target.checked },
      },
      { merge: true }
    );
  }

  useEffect(() => {
    if (user) {
      const unsubscribe = subscribeToConsultations();
      return () => unsubscribe();
    }
  }, [user]);

  if (!user) {
    return null; // or a loading indicator
  }

  return (
    <section className="tablePage">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button
            className="nav-link active"
            id="cosultaions-tab"
            data-bs-toggle="tab"
            data-bs-target="#cosultaions-tab-pane"
            type="button"
            role="tab"
            aria-controls="cosultaions-tab-pane"
            aria-selected="true"
          >
            Consultations
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            className="nav-link"
            id="completed-tab"
            data-bs-toggle="tab"
            data-bs-target="#completed-tab-pane"
            type="button"
            role="tab"
            aria-controls="completed-tab-pane"
            aria-selected="false"
          >
            Completed Consultations
          </button>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div
          className="tab-pane fade show active"
          id="cosultaions-tab-pane"
          role="tabpanel"
          aria-labelledby="cosultaions-tab"
          tabIndex="0"
        >
          <h2>Consultations</h2>
          <div className="tableres">
            <Table bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Message</th>
                  <th>Media</th>
                  <th>Created At</th>
                </tr>
              </thead>
              <tbody>
                {consultations.map(
                  (consultation) =>
                    !consultation.completed && (
                      <tr key={consultation.id}>
                        <td className="align-content-center">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={consultation.completed}
                            onChange={(e) => checked(e, consultation.id)}
                          />
                        </td>
                        <td>{consultation.name}</td>
                        <td>{consultation.email}</td>
                        <td>{consultation.message}</td>
                        <td>
                          {consultation.mediaUrl ? (
                            <a
                              href={consultation.mediaUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Download
                            </a>
                          ) : (
                            "No"
                          )}
                        </td>
                        <td>
                          {consultation.createdAt.toDate().toLocaleString()}
                        </td>
                      </tr>
                    )
                )}
              </tbody>
            </Table>
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="completed-tab-pane"
          role="tabpanel"
          aria-labelledby="completed-tab"
          tabIndex="0"
        >
          <h2>Completed Consultations</h2>
          <div className="tableres">
            <Table bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Message</th>
                  <th>Media</th>
                  <th>Created At</th>
                </tr>
              </thead>
              <tbody>
                {consultations.map(
                  (consultation) =>
                    consultation.completed && (
                      <tr key={consultation.id}>
                        <td className="align-content-center">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={consultation.completed}
                            onChange={(e) => checked(e, consultation.id)}
                          />
                        </td>
                        <td>{consultation.name}</td>
                        <td>{consultation.email}</td>
                        <td>{consultation.message}</td>
                        <td>
                          {consultation.mediaUrl ? (
                            <a
                              href={consultation.mediaUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Download
                            </a>
                          ) : (
                            "No"
                          )}
                        </td>
                        <td>
                          {consultation.createdAt.toDate().toLocaleString()}
                        </td>
                      </tr>
                    )
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TablePage;
