import React from "react";
import Footer from "../../components/Footer";
import Booking from "../../components/Booking";
import AboutUsImage from "../../images/About us.png";
import MortgageCancellationImage from "../../images/Mortgage Cancellation.png";
import TitleTransferImage from "../../images/Title transfer.png";
import MaintenanceFeesCancellationImage from "../../images/Maintenance Fees Cancellation.png";
import "./home.css";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <>
      <section id="home">
        <div className="home-overlay"></div>
        <div className="d-flex flex-column justify-content-center align-items-center home-content">
          <header className="home-header wow slideInDown text-center">
            WELCOME TO CONSUMER <br /> PROTECTION NETWORKS
          </header>
          <h4 className="home-subtitle wow animate__animated animate__zoomIn text-center">
            Cancel Your Timeshare
          </h4>
          <h5 className="text-center wow animate__animated animate__zoomIn">
            Your #1 Timeshare Exit Company
          </h5>
        </div>
      </section>

      <section id="Aboutus" className="container">
        <header className="about-header">About Us</header>
        <div className="row">
          <div className="col-md-6 align-self-center wow slideInLeft vertical-col">
            <h1>Timeshare Exit</h1>
            <p>
              Welcome to Consumer Protection Networks, where we've assisted
              countless clients in liberating themselves from the burdens of
              timeshare contracts. <br /> <br />
              Our premier service includes comprehensive title transfer
              solutions, effectively removing our clients' timeshare properties
              from their names legally and hassle-free. <br /> <br />
              Additionally, we specialize in timeshare mortgage and maintenance
              fees cancellation, providing a path to financial freedom for our
              valued clients. Join the ranks of satisfied customers who have
              found relief with Consumer Protection Networks.
            </p>
            <Link to="/About" className="btn2 btn-link2">
              <span className="icon2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-info-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zm0 12a1 1 0 0 0 1-1 1 1 0 0 0-2 0 1 1 0 0 0 1 1zm-.004-3a1 1 0 0 0 1-1V5a1 1 0 1 0-2 0v3a1 1 0 0 0 1 1z"
                  />
                </svg>
              </span>
              <span className="text2">More Info</span>
            </Link>
          </div>
          <div className="col-md-6 wow slideInRight vertical-col">
            <img src={AboutUsImage} alt="About Us" className="about-image" />
          </div>
        </div>
      </section>

      <section className="services-1">
        <header className="services-header">Our Services</header>
        <section className="box-section2">
          <div
            className="box-example2 animate__animated animate__zoomIn wow"
            data-wow-delay="0s"
          >
            <div className="box-content2">
              <h2>Mortgage Cancellation</h2>
              <img
                src={MortgageCancellationImage}
                alt="Mortgage Cancellation"
                className="img-fluid"
              />
              <p>
                Timeshare companies are notorious for using high-pressure sales
                tactics and giving out misleading information and false
                promises.
              </p>
            </div>
          </div>
          <div
            className="box-example2 animate__animated animate__zoomIn wow"
            data-wow-delay="0.25s"
          >
            <div className="box-content2">
              <h2>Title Transfer Services</h2>
              <img
                src={TitleTransferImage}
                alt="Title Transfer Services"
                className="img-fluid"
              />
              <p>
                We will help you legally transfer your timeshare property out of
                your name. Once completed, you will be free from all financial
                liability.
              </p>
            </div>
          </div>
          <div
            className="box-example2 animate__animated animate__zoomIn wow"
            data-wow-delay="0.5s"
          >
            <div className="box-content2">
              <h2>Maintenance Fees Cancellation</h2>
              <img
                src={MaintenanceFeesCancellationImage}
                alt="Maintenance Fees Cancellation"
                className="img-fluid"
              />
              <p>
                Cancelling your Timeshare ownership and maintenance fees can
                often be more simple than you think. Most Timeshare contracts
                are not worth the paper they're written on.
              </p>
            </div>
          </div>
        </section>
      </section>

      <section className="services-2">
        <header className="header-text">What Makes Us Different!</header>
        <div className="box-section">
          <div
            className="box-example wow animate__animated animate__zoomIn"
            data-wow-delay="0s"
          >
            <div className="box-content">
              <lord-icon
                src="https://cdn.lordicon.com/bqkijzun.json"
                trigger="hover"
                stroke="bold"
                colors="primary:#706464,secondary:#000000"
                style={{ width: "100px", height: "100px" }}
              />
              <h1>Happy clients</h1>
              <p>
                Hundreds of happy customer cannot be wrong. Customer reviews
                speak volumes for a business and the services they perform. We
                strive to maintain the highest level of service throughout your
                timeshare exit process with us.
              </p>
            </div>
          </div>

          <div
            className="box-example wow animate__animated animate__zoomIn"
            data-wow-delay="0.25s"
          >
            <div className="box-content">
              <lord-icon
                src="https://cdn.lordicon.com/qvyppzqz.json"
                trigger="hover"
                stroke="bold"
                colors="primary:#706464,secondary:#000000"
                style={{ width: "100px", height: "100px" }}
              />
              <h1>24/7 support</h1>
              <p>
                24/7 support means customers can get help and find answers to
                questions as soon as they come up—24/7 and in real-time.
              </p>
            </div>
          </div>

          <div
            className="box-example wow animate__animated animate__zoomIn"
            data-wow-delay="0.5s"
          >
            <div className="box-content">
              <lord-icon
                src="https://cdn.lordicon.com/kndkiwmf.json"
                trigger="hover"
                stroke="bold"
                colors="primary:#706464,secondary:#000000"
                style={{ width: "100px", height: "100px" }}
              />
              <h1>Experienced Team</h1>
              <p>
                We recognize that our success lies in the hands of our staff. We
                acknowledge this and are privileged to have such a diverse group
                of professionals that are friendly, courteous, and exceptional
                at what they do.
              </p>
            </div>
          </div>

          <div
            className="box-example wow animate__animated animate__zoomIn"
            data-wow-delay="0.75s"
          >
            <div className="box-content">
              <lord-icon
                src="https://cdn.lordicon.com/nchegqgo.json"
                trigger="hover"
                stroke="bold"
                colors="primary:#706464,secondary:#000000"
                style={{ width: "100px", height: "100px" }}
              />
              <h1>Proven Exit Strategy</h1>
              <p>
                We have demonstrated to be a formidable timeshare exit partner.
                Our experience and timeshare exit process has helped many
                timeshare owners put an end to their unwanted timeshare contract
                liabilities.
              </p>
            </div>
          </div>

          <div
            className="box-example wow animate__animated animate__zoomIn"
            data-wow-delay="1s"
          >
            <div className="box-content">
              <lord-icon
                src="https://cdn.lordicon.com/vistbkts.json"
                trigger="hover"
                stroke="bold"
                colors="primary:#706464,secondary:#000000"
                style={{ width: "100px", height: "100px" }}
              />
              <h1>Secure Agent Information</h1>
              <p>
                Means sensitive data agents handle, like customer details or
                company secrets. To keep it safe, organizations use measures
                such as access controls, encryption, and training for agents.
                Monitoring and auditing help detect and address security issues.
              </p>
            </div>
          </div>

          <div
            className="box-example wow animate__animated animate__zoomIn"
            data-wow-delay="1.25s"
          >
            <div className="box-content">
              <lord-icon
                src="https://cdn.lordicon.com/wyqtxzeh.json"
                trigger="hover"
                stroke="bold"
                colors="primary:#706464,secondary:#000000"
                style={{ width: "100px", height: "100px" }}
              />
              <h1>Money-back Guarantee</h1>
              <p>
                With decades of timeshare exit experience among CPN's leadership
                group, we are confident in our ability to deliver. We provide
                owners with a full money-back guarantee if our services are not
                completed within the agreed-upon time frame. This assurance
                underscores our commitment to your satisfaction and gives you
                peace of mind when choosing our services.
              </p>
            </div>
          </div>
        </div>
      </section>

      <Booking />

      <Footer />
    </>
  );
};

export default Home;
