import React, { useState } from "react";
import "animate.css";
import Footer from "../../components/Footer";
import Booking from "../../components/Booking";
import processImage from "../../images/Our process.png";
import bookAppointmentImage from "../../images/Book Appointment.png";
import analystCallImage from "../../images/Analyst Call.png";
import onboardingImage from "../../images/Onboarding.png";
import finalResolutionImage from "../../images/Final Resolution.png";

const Process = () => {
  const [activeSection, setActiveSection] = useState(1);

  const showSection = (sectionNumber) => {
    setActiveSection(sectionNumber);
  };
  return (
    <>
      <div id="client" style={{ marginTop: "100px" }}>
        <h2 className="text-center font-weight-bold">Process</h2>

        <div className="container">
          <div className="row">
            <div
              className="col-md-6 align-self-center wow slideInLeft"
              data-wow-duration="0.75s"
              data-wow-delay="0s"
            >
              <h1>Our Timeshare Exit Process</h1>
              <p>
                First and foremost, what we offer is experience, unparalleled
                customer service, and a proven timeshare resolution process that
                has allowed thousands of timeshare owners to successfully exit
                their unwanted timeshare contract. Understanding our process and
                setting expectations.
              </p>
            </div>
            <div
              className="col-md-6 wow slideInRight"
              data-wow-duration="0.75s"
              data-wow-delay="0s"
            >
              <img src={processImage} alt="process" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <ul className="progressbar wow animate__animated animate__zoomIn">
          {[1, 2, 3, 4].map((section) => (
            <li
              key={section}
              className={section === activeSection ? "active" : ""}
              onClick={() => showSection(section)}
            ></li>
          ))}
        </ul>
      </div>
      <div className="container">
        <div
          id="section1"
          className={`section wow animate__animated animate__zoomIn ${
            activeSection === 1 ? "active" : ""
          }`}
        >
          <h2 style={{ alignItems: "center", textAlign: "center" }}>
            Step 1
            <br />
            Specialist Call
          </h2>
          <img src={bookAppointmentImage} alt="Placeholder" />
          <h3 style={{ alignItems: "center", textAlign: "center" }}>
            Booking of Appointment
          </h3>
          <p style={{ alignItems: "center", textAlign: "center" }}>
            Consumer Protection Network makes booking appointments to exit
            timeshare agreements easy and transparent. You can schedule online
            or by contacting our team. Our experts are here to guide you through
            exit strategies, legal options, and financial considerations. We're
            committed to making the process clear and accessible, empowering you
            on your journey to timeshare freedom.
          </p>
        </div>

        <div
          id="section2"
          className={`section wow animate__animated animate__zoomIn ${
            activeSection === 2 ? "active" : ""
          }`}
        >
          <h2 style={{ alignItems: "center", textAlign: "center" }}>
            Step 2 <br />
            Analyst Call
          </h2>
          <img src={analystCallImage} alt="Placeholder" />
          <h3 style={{ alignItems: "center", textAlign: "center" }}>
            Qualification Process
          </h3>
          <p style={{ alignItems: "center", textAlign: "center" }}>
            Simplicity and clarity are our priorities. Booking is
            straightforward—either online or by reaching out to our team
            directly. During your call, our experts will provide insights and
            guidance on various topics, including market analysis, industry
            trends, and strategic planning. We're dedicated to ensuring a
            seamless experience that empowers you with the information you need.
          </p>
        </div>

        <div
          id="section3"
          className={`section wow animate__animated animate__zoomIn ${
            activeSection === 3 ? "active" : ""
          }`}
        >
          <h2 style={{ alignItems: "center", textAlign: "center" }}>
            Step 3 <br />
            Onboarding
          </h2>
          <img src={onboardingImage} alt="Placeholder" />
          <h3 style={{ alignItems: "center", textAlign: "center" }}>
            Client Services and Case Management
          </h3>
          <p style={{ alignItems: "center", textAlign: "center" }}>
            Streamlining the onboarding process for Client Services and Case
            Management at Consumer Protection Network is our goal. Booking
            appointments is effortless, whether online or by contacting our team
            directly. During these sessions, our specialists provide
            comprehensive guidance on service offerings, case management
            procedures, and client support systems. We're committed to ensuring
            a smooth transition that equips you with the tools and knowledge
            needed for effective client service and case management.
          </p>
        </div>

        <div
          id="section4"
          className={`section wow animate__animated animate__zoomIn ${
            activeSection === 4 ? "active" : ""
          }`}
        >
          <h2 style={{ alignItems: "center", textAlign: "center" }}>
            Step 4
            <br />
            Final Resolution
          </h2>
          <img src={finalResolutionImage} alt="Placeholder" />
          <h3 style={{ alignItems: "center", textAlign: "center" }}>
            Voila! A Successful Timeshare Exit
          </h3>
          <p style={{ alignItems: "center", textAlign: "center" }}>
            Achieving a successful timeshare exit with our company is a
            straightforward process. Through our streamlined appointment booking
            system, you can easily schedule a consultation either online or by
            reaching out to our team directly. During these sessions, our
            experts will guide you through the steps involved in exiting your
            timeshare agreement, discussing tailored strategies, legal
            considerations, and financial implications. We're committed to
            ensuring a seamless experience that leads to your successful
            timeshare exit.
          </p>
        </div>
      </div>
      <Booking />

      <Footer />
    </>
  );
};

export default Process;
