import React, { useState } from "react";
import {
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaEnvelope,
  FaTwitter,
  FaFacebookF,
  FaYoutube,
  FaInstagram,
  FaLinkedinIn,
  FaAngleRight,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

const Footer = () => {
  const [email, setEmail] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = () => {
    if (!email) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter an email address!",
      });
      return;
    }

    setEmail("");
    Swal.fire({
      icon: "info",
      title: "Coming Soon",
      text: "Newsletter subscription will be available soon.",
    });
  };

  return (
    <footer
      className="container-fluid text-light footer mt-5 pt-5 wow fadeIn"
      data-wow-delay="0.1s"
    >
      <div className="container py-5 px-lg-5">
        <div className="row g-5">
          <div className="col-md-6 col-lg-3">
            <h5 className="text-white mb-4">Get In Touch</h5>
            <p>
              <FaMapMarkerAlt /> Mailing Address: 24624 Interstate 45 N, Suite
              200, Spring, TX 77386
            </p>
            <p>
              <FaPhoneAlt /> 866-682-1706
            </p>
            <p>
              <FaEnvelope />{" "}
              <a
                href="mailto:clientservices@consumerprotectionnetworks.com"
                className="text-white text-decoration-none"
              >
                clientservices@consumerprotectionnetworks.com
              </a>
            </p>
            <div className="d-flex pt-2">
              <Link className="btn btn-outline-light btn-social" to="/Social">
                <FaTwitter />
              </Link>
              <Link className="btn btn-outline-light btn-social" to="/Social">
                <FaFacebookF />
              </Link>
              <Link
                className="btn btn-outline-light btn-social"
                to="https://www.youtube.com/@ConsumerProtectionNetworks"
              >
                <FaYoutube />
              </Link>
              <Link className="btn btn-outline-light btn-social" to="/Social">
                <FaInstagram />
              </Link>
              <Link className="btn btn-outline-light btn-social" to="/Social">
                <FaLinkedinIn />
              </Link>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <h5 className="text-white mb-4">Popular Link</h5>
            <Link className="btn btn-link" to="/About">
              {" "}
              <FaAngleRight /> About Us
            </Link>
            <Link className="btn btn-link" to="#booking">
              {" "}
              <FaAngleRight /> Contact Us
            </Link>
            <Link className="btn btn-link" to="/Reviews">
              {" "}
              <FaAngleRight /> Reviews
            </Link>
            <Link className="btn btn-link" to="/Process">
              <FaAngleRight /> Process
            </Link>
          </div>
          <div className="col-md-6 col-lg-3">
            <h5 className="text-white mb-4">Operation Hours</h5>
            <p>
              <strong>Mon</strong> 09:00 am – 05:00 pm
            </p>
            <p>
              <strong>Tue</strong> 09:00 am – 05:00 pm
            </p>
            <p>
              <strong>Wed</strong> 09:00 am – 05:00 pm
            </p>
            <p>
              <strong>Thu</strong> 09:00 am – 05:00 pm
            </p>
            <p>
              <strong>Fri</strong> 09:00 am – 05:00 pm
            </p>
            <p>
              <strong>Sat</strong> Closed
            </p>
            <p>
              <strong>Sun</strong> Closed
            </p>
          </div>
          <div className="col-md-6 col-lg-3">
            <h5 className="text-white mb-4">Newsletter</h5>
            <p>
              Subscribe for concise, essential updates straight to your inbox
            </p>
            <div className="position-relative w-100 mt-3">
              <input
                className="form-control border-0 rounded-pill w-100 ps-4 pe-5"
                type="email"
                placeholder="Your email"
                style={{ height: "48px" }}
                value={email}
                onChange={handleEmailChange}
              />
              <br />
              <button
                type="button"
                className="btn shadow-none position-absolute top-0 end-0 mt-1 me-2"
                style={{ backgroundColor: "#0c0c0d", color: "#ffffff" }}
                onClick={() => {
                  handleSubmit();
                }}
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container px-lg-5">
        <div className="copyright">
          <div className="row">
            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
              ©{" "}
              <Link className="border-bottom" to="/">
                Consumer Protection Networks
              </Link>
              , All Right Reserved. Designed By{" "}
              <a className="border-bottom" href="https://doorclosers.me">
                DoorClosers
              </a>
            </div>
            <div className="col-md-6 text-center text-md-end">
              <div className="footer-menu">
                <a href="/">Home</a>
                <a href="/PrivacyPolicy">Privacy Policy</a>
                <a href="/FAQ">FQAs</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
