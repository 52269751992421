import React, { useState } from "react";
import { doc, setDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, storage } from "../services/firebase";
import Swal from "sweetalert2";
import { v4 as uuidv4 } from "uuid";
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from "@emailjs/browser";

const Booking = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    mediaUrl: "",
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [recaptchaValue, setRecaptchaValue] = useState("");
  const recaptchaRef = React.useRef();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Validate email format
    if (name === "email") {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      setEmailError(emailPattern.test(value) ? "" : "Invalid email format");
    }
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (emailError) {
      Swal.fire({
        icon: "error",
        title: "Invalid Email",
        text: emailError,
        showConfirmButton: true,
      });
      return;
    }
    setIsLoading(true);

    const uuid = uuidv4();
    const docName = "allConsultations";

    let mediaUrl = "";
    if (selectedFile) {
      const storageRef = ref(storage, `/media/${uuid}_${selectedFile.name}`);
      try {
        await uploadBytes(storageRef, selectedFile);
        mediaUrl = await getDownloadURL(storageRef);
      } catch (error) {
        console.error("Error uploading file: ", error);
      }
    }

    const updatedFormData = {
      ...formData,
      mediaUrl,
      createdAt: new Date(),
    };
    var templateParams = {
      name: formData.name,
      email: formData.email,
      message: formData.message,
      url: mediaUrl,
    };

    try {
      await setDoc(
        doc(db, "consultations", docName),
        {
          [uuid]: updatedFormData,
        },
        { merge: true }
      );
      emailjs.init({
        publicKey: process.env.REACT_APP_EMAIL_JS_PUBLIC_KEY,
      });
      emailjs
        .send(
          process.env.REACT_APP_EMAIL_JS_SERVICE_ID,
          process.env.REACT_APP_EMAIL_JS_TEMPLATE_ID,
          templateParams
        )
        .then(
          function (response) {
            console.log("SUCCESS!", response.status, response.text);
          },
          function (err) {
            console.log("FAILED...", err);
          }
        );
      Swal.fire({
        icon: "success",
        title: "Consultation Sent Successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      setFormData({
        name: "",
        email: "",
        message: "",
        mediaUrl: "",
      });
      setSelectedFile(null);
      setRecaptchaValue("");
      document.getElementById("media").value = "";
      recaptchaRef.current.reset();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Something Went Wrong",
        showConfirmButton: false,
        timer: 1500,
      });
      console.error("Error submitting consultation: ", error);
    } finally {
      setIsLoading(false);
    }
  };
  function gtag_report_conversion() {
    window.gtag("event", "conversion", {
      send_to: "AW-16818208218/hZinCJ6b5fUZENr7xdM-",
      value: 1.0,
      currency: "USD",
    });
    return false;
  }
  return (
    <div id="booking" style={{ padding: "20px" }}>
      <div className="form">
        <div className="form-section">
          <h1 className="form-heading">REQUEST A FREE CONSULTATION NOW!</h1>
        </div>

        <div className="form-content">
          <form id="myform" className="form-animate" onSubmit={handleSubmit}>
            <label htmlFor="name">Full Name</label>
            <input
              id="name"
              name="name"
              type="text"
              placeholder="Enter your full name"
              className="form-control"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <label htmlFor="email">Email</label>
            <input
              id="email"
              name="email"
              type="email"
              placeholder="Enter your email"
              className="form-control"
              value={formData.email}
              onChange={handleChange}
              required
            />
            {emailError && (
              <div className="error-message text-danger">{emailError}</div>
            )}
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              placeholder="Enter your message"
              className="form-control"
              value={formData.message}
              onChange={handleChange}
              required
            />
            <label htmlFor="media">Attach Files</label>
            <input
              type="file"
              id="media"
              placeholder="Attach Your File"
              accept="image/*, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              style={{ display: "block" }}
              onChange={handleFileChange}
              maxSize={10485760} // 10 MB in bytes
            />
            <ReCAPTCHA
              ref={recaptchaRef}
              className="mt-3"
              sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
              onChange={(val) => setRecaptchaValue(val)}
            />
            <div style={{ textAlign: "center" }}>
              <button
                id="submit"
                type="submit"
                className="form-submit"
                disabled={!recaptchaValue || isLoading}
                onClick={() => gtag_report_conversion()}
              >
                {isLoading ? "Sending..." : "Send Now"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Booking;
