import React from "react";

import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider,
} from "react-router-dom";
import Navbarr from "./components/Navbarr";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./App.css";
import ScrollToTop from "./ScrollToTop";
import Home from "./pages/Home/Home";
import Reviews from "./pages/Reviews/Reviews";
import Process from "./pages/Process/Process";
import FAQ from "./pages/FAQ/FAQ";
import About from "./pages/About/About";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import Social from "./components/Social";
import SignIn from "./pages/SignIn/SignIn";
import TablePage from "./pages/TablePage/TablePage";
function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/" element={<Root />}>
          <Route index element={<Home />} />
          <Route path="/About" element={<About />} />
          <Route path="/FAQ" element={<FAQ />} />
          <Route path="/Process" element={<Process />} />
          <Route path="/Reviews" element={<Reviews />} />
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/Social" element={<Social />} />
          <Route path="/SignIn" element={<SignIn />} />
          <Route path="/dashboard" element={<TablePage />} />
        </Route>
      </>
    )
  );

  return (
    <div className={`app`}>
      <RouterProvider router={router}></RouterProvider>
    </div>
  );
}

export default App;

const Root = () => {
  return (
    <div className={`App`}>
      <ScrollToTop />
      <Navbarr />
      <Outlet />
    </div>
  );
};
