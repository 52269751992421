import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { auth } from "../../services/firebase";

import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import onboardingImage from "../../images/Onboarding.png";

function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/dashboard"); // Redirect to table page on successful sign-in
    } catch (error) {
      setError("Invalid email or password. Please try again.");
    }
  };

  return (
    <>
      <section className="signin">
        <Container>
          <Row>
            <Col lg={6} md={6} sm={12}>
              <div className="formDiv">
                <h2>Sign In</h2>

                <form onSubmit={handleSubmit}>
                  <div>
                    <input
                      className="form-control"
                      type="email"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Email Address"
                      required
                    />
                  </div>
                  <div>
                    <input
                      className="form-control"
                      name="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      type="password"
                      placeholder="Password"
                      required
                    />
                  </div>
                  {error && <div className="error-message">{error}</div>}
                  <div>
                    <button type="submit">Sign In</button>
                  </div>
                </form>
              </div>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <img src={onboardingImage} alt="" />
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
}

export default SignIn;
