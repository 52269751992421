import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer";
import Booking from "../../components/Booking";
import AboutUsImage from "../../images/About us.png";
import OurMissionImage from "../../images/Our mission.png";
import OurExpertiseImage from "../../images/Our Expertise.png";
import OurSecureImage from "../../images/Our Secure.png";

const About = () => {
  const [count1, setCount1] = useState(8000);
  const [count2, setCount2] = useState(0);
  const [count3, setCount3] = useState(0);

  useEffect(() => {
    const counter = (setState, start, end, duration) => {
      let current = start;
      const increment = end > start ? 1 : -1;
      const step = Math.abs(Math.floor(duration));
      const timer = setInterval(() => {
        current += increment;
        setState(current);
        if (current === end) {
          clearInterval(timer);
        }
      }, step);
    };

    counter(setCount1, 8000, 10000, 0);
    counter(setCount2, 0, 200, 30);
    counter(setCount3, 0, 40, 50);
  }, []);

  return (
    <>
      <section id="Aboutus" className="about-section">
        <h2 className="about-heading">About Us</h2>

        <div className="container">
          <div className="row">
            <div
              className="col-md-6 align-self-center wow slideInLeft"
              data-wow-duration="0.75s"
              data-wow-delay="0s"
            >
              <h1>Timeshare Exit</h1>
              <p>
                Welcome to Consumer Protection Networks, where we've assisted
                countless clients in liberating themselves from the burdens of
                timeshare contracts.
                <br />
                <br />
                Our premier service includes comprehensive title transfer
                solutions, effectively removing our clients' timeshare
                properties from their names legally and hassle-free.
                <br />
                <br />
                Additionally, we specialize in timeshare mortgage and
                maintenance fees cancellation, providing a path to financial
                freedom for our valued clients. Join the ranks of satisfied
                customers who have found relief with Consumer Protection
                Networks.
              </p>
            </div>
            <div
              className="col-md-6 wow slideInRight"
              data-wow-duration="0.75s"
              data-wow-delay="0s"
            >
              <img
                src={AboutUsImage} // Use imported images
                alt="About Us"
                className="img-fluid"
              />
            </div>
          </div>
        </div>

        <div className="container stats-container">
          <div className="row">
            <div
              className="col-md-4 col-sm-12 wow slideInLeft"
              data-wow-duration="0.75s"
              data-wow-delay="0s"
            >
              <span className="display-4">{count1}+</span>
              <br />
              <h4>Client Consultations</h4>
            </div>
            <div
              className="col-md-4 col-sm-12 wow slideInLeft"
              data-wow-duration="0.75s"
              data-wow-delay="0s"
            >
              $<span className="display-4">{count2} </span>Million +
              <br />
              <h4>Successfully Exited In Timeshare</h4>
            </div>
            <div
              className="col-md-4 col-sm-12 wow slideInLeft"
              data-wow-duration="0.75s"
              data-wow-delay="0s"
            >
              <span className="display-4">{count3}+</span>
              <br />
              <h4>Years of Combined Timeshare Exit Experience</h4>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div
              className="col-md-6 wow slideInLeft"
              data-wow-duration="0.75s"
              data-wow-delay="0s"
            >
              <img
                src={OurMissionImage} // Use imported images
                alt="Our Mission"
                className="img-fluid"
              />
            </div>
            <div
              className="col-md-6 align-self-center wow slideInRight"
              data-wow-duration="0.75s"
              data-wow-delay="0s"
            >
              <h1>Our Mission</h1>
              <p>
                At Consumer Protection Networks, our mission is to empower and
                support individuals seeking relief from the burdens of timeshare
                ownership. Committed to transparency, integrity, and unwavering
                consumer advocacy, we strive to be the trusted partner that
                guides our clients through a seamless exit process. By combining
                industry expertise with a relentless dedication to protecting
                consumer rights, we aim to provide a pathway to financial
                freedom and peace of mind. Our mission is to champion fairness,
                honesty, and accountability in the timeshare exit landscape,
                ensuring that every client can confidently reclaim control over
                their financial future.
              </p>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div
              className="col-md-6 align-self-center wow slideInLeft"
              data-wow-duration="0.75s"
              data-wow-delay="0s"
            >
              <h1>Our Expertise</h1>
              <p>
                Our expertise extends beyond mere knowledge; we've also
                cultivated a vast network of industry professionals and
                resources. This enables us to navigate the nuances of each
                individual case and identify the most appropriate strategies for
                successfully canceling timeshare contracts.
                <br />
                We understand that every client's situation is unique, which is
                why we take the time to thoroughly analyze and assess their
                specific circumstances. Our experts carefully review the terms
                and conditions of the timeshare contract, identify any potential
                breaches or illegalities, and formulate a customized plan for
                cancellation.
              </p>
            </div>
            <div
              className="col-md-6 wow slideInRight"
              data-wow-duration="0.75s"
              data-wow-delay="0s"
            >
              <img
                src={OurExpertiseImage} // Use imported images
                alt="Our Expertise"
                className="img-fluid"
              />
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div
              className="col-md-6 wow slideInLeft"
              data-wow-duration="0.75s"
              data-wow-delay="0s"
            >
              <img
                src={OurSecureImage} // Use imported images
                alt="Our Privacy"
                className="img-fluid"
              />
            </div>
            <div
              className="col-md-6 align-self-center wow slideInRight"
              data-wow-duration="0.75s"
              data-wow-delay="0s"
            >
              <h1>Our Privacy</h1>
              <p>
                At Consumer Protection Networks, we prioritize the security and
                confidentiality of your information. We do not share any
                personal data provided to us with third parties. Your privacy
                matters to us, and we are committed to safeguarding it at all
                times.
                <br />
                We may collect personal information from you when you visit our
                website, register for an account, make a purchase, or subscribe
                to our services. This information may include your name, email
                address, phone number, billing address, and payment details.
                <br />
                We use the information we collect to provide and improve our
                services, process transactions, communicate with you, and
                personalize your experience. We may also use your information to
                send you promotional offers, updates, and other marketing
                communications.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Booking />
      <Footer />
    </>
  );
};

export default About;
